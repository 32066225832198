import React, { useState, useRef } from 'react'
import logoImg from '../elements-contact/images/logo@2x.png'
import logoMobile from '../elements-contact/images/logo.png'
import SEO from '../components/seo'

import '../elements-contact/css/bootstrap.css'
import '../elements-contact/style.css'
import '../elements-contact/ventana.css'
import '../elements-contact/css/font-icons.css'
// import '../elements-contact/css/animate.css'
import '../elements-contact/css/fonts/stylesheet.css'
import '../elements-contact/contacto.css'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
  name: '',
  lastname: '',
  email: '',
  phone: '',
  measure: '',
}

function ContactPage() {
  const [state, setState] = useState(initialState)

  function handleSubmit(event) {
    event.preventDefault()
    const requestOptions = {
      headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        nombre: state.name,
        apellido: state.lastname,
        correo: state.email,
        telefono: state.phone,
        medida: state.measure,
      }),
    }
    fetch(
      'https://hooks.zapier.com/hooks/catch/4568688/bshd0vq/',
      requestOptions,
    )
      .then(() => {
        toast.success('Gracias, tus datos han sido enviados')
        window.location.href = `/thankyouContacto`
        setState(initialState)
      })
      .catch(() => {
        toast.error(
          'Ups, tuvimos un error al enviar tus datos, verifica e intenta nuevamente',
        )
      })
  }

  function handleChange({ target: { name, value } }) {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <div>
      <ToastContainer />
      <SEO title="Be Grand | Downtown Reforma" main />
      <header
        id="header"
        className="transparent-header "
        data-sticky-shrink="false"
        data-animate="fadeInDown"
      >
        <div id="header-wrap">
          <div className="container">
            <div className="header-row">
              <div id="logo">
                <a
                  href="/contacto"
                  data-dark-logo={logoImg}
                  className="standard-logo"
                >
                  <img src={logoImg} alt="Be Grand" />
                </a>
                <a
                  href="/contacto"
                  data-dark-logo={logoImg}
                  className="retina-logo"
                >
                  <img src={logoImg} alt="Be Grand" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header-wrap-clone"></div>
      </header>
      <div id="wrapper" className="clearfix">
        <section
          id="slider"
          className="slider-element min-vh-60 min-vh-md-100 dark include-header include-topbar"
        >
          <div className="slider-inner">
            <div
              className="fslider h-100 position-absolute"
              data-speed="1000"
              data-pause="5000"
              data-animation="fade"
              data-arrows="false"
              data-pagi="false"
            >
              <div className="flexslider">
                <div className="slider-wrap">
                  <div
                    className="slide"
                    style={{
                      background:
                        'url("../elements-contact/images/fondo1.jpg") center center',
                      backgroundSize: 'cover',
                    }}
                  ></div>
                  <div
                    className="slide"
                    style={{
                      background:
                        'url("../elements-contact/images/fondo2.jpg") center center',
                      backgroundSize: 'cover',
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="vertical-middle">
              <div className="container text-center">
                <div className="emphasis-title m-0">
                  <div className="vertical-middle slider-element-fade">
                    <div className="container ">
                      <div className="login-23">
                        <div className=" p-0 m-0">
                          <div className="col-md-12 pad-0">
                            <div className="row login-box-12">
                              <div
                                className="col-lg-8 offset-lg-2 col-sm-12 align-self-center aligncenter align-items-center logobg m-0 pb-3"
                                data-animate="fadeInDown"
                              >
                                <img src={logoMobile} />
                              </div>
                              <div
                                className="col-lg-10 offset-lg-1 col-sm-12 align-self-center header-form header-form-contact"
                                data-animate="fadeInRight"
                              >
                                <h2 className="titulo">
                                  PREVENTA DE OFICINAS EN REFORMA
                                </h2>
                                <h3 className="subtitulo">
                                  UN <span>GRAN ESPACIO</span> PARA UNA
                                  <span> GRAN EMPRESA</span>
                                </h3>
                              </div>
                              <div
                                className="col-lg-6 offset-lg-3 col-sm-12 align-self-center subheader"
                                data-animate="fadeInLeft"
                              >
                                <div>
                                  OFICINAS DESDE{' '}
                                  <span>
                                    87 m<sup>2</sup>
                                  </span>
                                </div>
                              </div>

                              <div
                                className="col-lg-10 col-sm-12 align-self-center titulo-formulario"
                                data-animate="fadeInRight"
                              >
                                <div>
                                  COMPLETA EL SIGUIENTE FORMULARIO PARA RECIBIR
                                  MÁS INFORMACIÓN
                                </div>
                              </div>

                              <div
                                className="col-lg-12 col-sm-12 align-self-center formulario"
                                data-animate="fadeInRight"
                              >
                                <form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          name="name"
                                          value={state.name}
                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="*Nombre"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          name="lastname"
                                          value={state.lastname}
                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="*Apellido(s)"
                                          required="required"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <input
                                          type="email"
                                          name="email"
                                          value={state.email}
                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="*Correo"
                                          required="required"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          name="phone"
                                          value={state.phone}
                                          onChange={handleChange}
                                          className="form-control"
                                          placeholder="Teléfono"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12 text-left">
                                      <div className="form-group">
                                        <label>
                                          Selecciona la medida de la oficina que
                                          estás buscando
                                        </label>
                                        <br></br>
                                        <select
                                          className="form-control"
                                          name="measure"
                                          value={state.measure}
                                          onChange={handleChange}
                                          required
                                        >
                                          <option value="">
                                            Opciones Disponibles
                                          </option>
                                          <option value="87a250m2">
                                            Desde 87m² a 250m²
                                          </option>
                                          <option value="250a350m2">
                                            250m² a 350m²
                                          </option>
                                          <option value="350a500m2">
                                            350m² a 500m²
                                          </option>
                                          <option value="mas500m2">
                                            Más de 500 m²
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="clear clear">&nbsp;</div>
                                    <div className="col-lg-12 pt-3">
                                      <label>
                                        <input
                                          type="checkbox"
                                          value=""
                                          required="required"
                                        />{' '}
                                        Acepto las{' '}
                                        <a
                                          href="https://www.begrand.mx/cms/storage/files/1/Politicas%20de%20privacidad/begrand-politicas-privacidad.pdf"
                                          target="_blank"
                                        >
                                          POLÍTICAS DE PRIVACIDAD
                                        </a>
                                        de Downtown Reforma
                                      </label>
                                      <div className="form-group">
                                        <button
                                          type="submit"
                                          className="btn-formulario "
                                        >
                                          SOLICITAR INFORMACIÓN
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div
                                className="col-lg-12 col-sm-12 align-self-center pie"
                                data-animate="fadeInUp"
                              >
                                <div>Derechos reservados 2020</div>
                                <div>Downtown Reforma</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ContactPage
